import React from "react";
import { Redirect } from "react-router-dom"










// Authentication related pages
import RegisterUser from "pages/Authentication/RegisterUser"
import LoginUser from "pages/Authentication/LoginUser"
import EmailVerify from "pages/Authentication/EmailVerify";
import UserForgetPassword from "pages/Authentication/UserForgetPassword";
import CreatePin from "pages/Authentication/CreatePin";
import SaveBankDetails from "pages/Authentication/SaveBankDetails";
import ChangePassword from "pages/Authentication/ResetPassword";







// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Spoowealth from "../pages/Spoowealth/Plans"
import SpoowealthDetails from "../pages/Spoowealth/PlanDetails"
import Profile from "../pages/Users/Profile"

import Spoosafe from "../pages/Spoosafe/Plans"
import SpoosafeDetails from "../pages/Spoosafe/PlanDetails"

import Spooflex from "../pages/Spooflex/Plans"
import SpooflexDetails from "../pages/Spooflex/PlanDetails"

import SpooCircle from "../pages/Spoocircle/Plans"
import SpoocircleDetails from "../pages/Spoocircle/PlanDetails"

import Transactions from "../pages/Transactions/transactions"

import Savings from "../pages/Savings/Savings"




const authProtectedRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/spoowealth", component: Spoowealth},
    { path: "/plan-details/:id", component: SpoowealthDetails},
    { path: "/spoosafe", component: Spoosafe},
    { path: "/spoosafe-details/:id", component: SpoosafeDetails},
    { path: "/spooflex", component: Spooflex},
    { path: "/spooflex-details/:id", component: SpooflexDetails},
    { path: "/spoocircle", component: SpooCircle},
    { path: "/spoocircle-details/:id", component: SpoocircleDetails},
    { path: "/profile", component: Profile},
    { path: "/transactions", component: Transactions},
    { path: "/savings", component: Savings},
    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/register", component: RegisterUser},
  { path: "/verify-token", component: EmailVerify},
  { path: "/forgot-password", component: UserForgetPassword},
  { path: "/login", component: LoginUser},
  { path: "/create-pin", component: CreatePin},
  { path: "/save-bank", component: SaveBankDetails},
  { path: "/change-password", component: ChangePassword},
]

export { authProtectedRoutes, publicRoutes }



import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";

import NumberFormat from 'react-number-format';
import ProgressBar from "@ramonak/react-progress-bar"
import axios from "axios";
import {
  Container,
  Row,
  Col,
  Card,
  Modal,
  ModalBody
} from "reactstrap";
import DashSide from './DashSide'
import Savings from "../../components/Common/Savings"
import Services from "../../components/Common/Services"
import Trans from "../../components/Common/TransactionCom"
import GeneralCard from "../../components/Common/GeneralCard"



const API_URL = process.env.REACT_APP_BASE_URL


const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.interceptors.request.use((req) => {
  if(localStorage.getItem('profile')){
      req.headers.Authorization = `Bearer ${ JSON.parse(localStorage.getItem('profile')).token}`,
      req.headers.Accept = 'application/json'
  }
  return req;
});




const Dashboard = props => {

  const [userBanks, setUserBanks] = useState([])
  const [balance, setBalance] = useState(0)
  const [transactions, setTransactions] = useState([])
  const [modal, setModal] = useState(false)
  const [transaction, setTransaction] = useState()


  const getUserWallet = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/naira-wallet`).then((res) => {
      setBalance(res.data.balance)
    }).catch((error) => {
     console.log(error)
    })
  }
  
  const getUserBanks = async () => {
    axiosApi.get(`${API_URL}/api/v1/user/banks`).then((res) => {
      setUserBanks(res.data.data)
    }).catch((error) => {
  
    })
  }

  const getUserTransactions = () => {
    let limit = 5
    axiosApi.get(`${API_URL}/api/v1/user/transactions/${limit}`).then((res) => {
      setTransactions(res.data.data)
    }).catch((error) => {
  
    })
  }
  
  
  const toggle = () => {
    setShow(!show)
  }
  
  useEffect(() => {
    getUserWallet()
    getUserBanks()
    getUserTransactions()
  }, [balance])

  const transactionDetails = async (trans) => {
    setTransaction(trans)
    setModal(true)
}

const toggleTrans = () => {
  setModal(!modal)
}
  







  return (
    <React.Fragment>
      
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Save, Earn and Spend</title>
        </MetaTags>
        <Container fluid>
           <Row>
             <Col md="10">
               <Row>
               <Col md="5" >
                 <Row>
                    <Col md="12">
                        <DashSide balance={balance} userBanks={userBanks} />
                    </Col>

                    <Col md="12" style={{ backgroundColor: "white", paddingTop: "20px"}}>
                      <h3>Our Services</h3> 
                      <hr />
                     {/* <Services id={1} title="Airtime Topup" details="Buy mtn, airtel, glo & 9mobile airtime" link="/airtime"  />
                     <Services id={2} title="Data Topup" details="Buy airtime with all networks" link="/data"  />
                     <Services id={3} title="Cable" details="Subscribe your GoTv, Dstv & Smile etc." link="/cable"  />
                     <Services id={4} title="Electricity" details="Get your electricity bill paid" link="/electricity"  />
                     <Services id={5} title="Send Money" details="Send money to bank or spootag with ease." link="/sendmoney"  /> */}
                     <Services id={6} title="Savings" details="Start saving to earn weekly." link="/savings"  />
                     {/* <Services id={7} title="Wallets" details="Virtual wallets for all your transactions" link="/wallets"  />
                     <Services id={7} title="Salari" details="Business tool for smes" link="/salary"  /> */}
                     <hr />

                    </Col>
                 </Row>
             
             </Col>
                   <Col md="6">
                     <div className='plan-head'>
                        <h4 style={{ marginBottom: '20px' }}>Transactions</h4>
                     </div>

                     {transactions.length > 0 ? (
                        transactions.map((tran) => (
                          <>
                          <Trans type={tran.type} status={tran.status} date={tran.createdAt} amount={tran.amount} onclick={() => transactionDetails(tran)} />
                         </>
                        ))
                      
                     ) : (
                        <>
                          <p>You have no transaction at the moment..</p>
                        </>
                     )}
                     {transactions.length > 0 && (
                      <a href="/transactions"><p style={{ color: "#6210CC"}}>View All Transactions</p></a>
                     )}
                        

                    
          
                 </Col>
               </Row>
             </Col>

           


             
            
           </Row>
        </Container>
      </div>

      <Modal   isOpen={modal} toggle={toggleTrans}  size="md" centered={true} >
          <h4 style={{ textAlign: "center", paddingTop: "10px"}}>Transaction Details</h4>
           <ModalBody className="py-3 px-5">
            <Card style={{ padding: "10px"}}>
            {transaction?.type === "WITHDRAWAL" ? (
            <h4 style={{ textAlign: "center"}}>
                <NumberFormat
                      value={transaction.amount} 
                      className="foo"
                      displayType={'text'}
                      thousandSeparator={true}
                      renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
               /> 
            </h4>
            ) : transaction?.type === "FUNDING" ? (
              <h4 style={{ textAlign: "center"}}>
              <NumberFormat
                    value={transaction.amount} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
             /> 
          </h4>
            ) : transaction?.type === "SPOOWEALTH" ? (<h4>- ₦{transaction?.amount}</h4>) : <h4>₦{transaction?.amount}</h4>}
            <p style={transaction?.status === "SUCCESS" ? {color: "green", textAlign: "center"} : transaction?.status === "PENDING" ? {color: "orange", textAlign: "center"} : null}>{transaction?.status === "SUCCESS" ? "Succesfull" : transaction?.status === "PENDING" ? "Pending" : null}</p>
            <div>
              <span>Amount</span>
              <span style={{ float: "right"}}>
              <NumberFormat
                    value={transaction?.amount} 
                    className="foo"
                    displayType={'text'}
                    thousandSeparator={true}
                    renderText={(value, props) => <div {...props}>+ &#8358;{value}</div>}
             /> 
              </span> 
              <br />

              <span>Transaction Type</span>
              <span style={{ float: "right"}}>{transaction?.type}</span> <br />

              <span>Narration</span>
              <span style={{ float: "right"}}>{transaction?.narration}</span> <br />

              <span>Payment Method</span>
              <span style={{ float: "right"}}>Balance</span> <br />
              
            </div>
            </Card>
            </ModalBody>
     </Modal>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
  userDetails: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default Dashboard
